import * as React from 'react'
import PropTypes from 'prop-types'
import loadable from '@loadable/component'
import './styles.scss'

const ReactPlayer = loadable(() => import('react-player/wistia'))

const VideoPlayer = ({ data }) => {
    const VideoUrlSlice = data.slice(42, 59)
    const videoPlaceholder = `https://fast.wistia.com/embed/${VideoUrlSlice}/swatch`

    return (
        <div
            className="c-video-player"
            style={{ backgroundImage: `url(${videoPlaceholder})` }}
        >
            <ReactPlayer
                className="c-video-player__player"
                url={data}
                width="100%"
                height="100%"
            />
        </div>
    )
}

VideoPlayer.propTypes = {
    /**
     * Data
     */
    data: PropTypes.string.isRequired
}

VideoPlayer.defaultProps = {
    data: 'https://protect-line-marketing.wistia.com/medias/sce6ad8azy'
}

export default VideoPlayer
